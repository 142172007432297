<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">
              {{ $t('MENU.MAIN_ASSETS.MAIN_ASSETS_TITLE') }}
            </h3>
          </div>
        </div>
        <div class="card-body">
          <div>
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="getMainAssets"
              :items-per-page="10"
              loading-text="..."
              single-select
              @click:row="rowClick"
              item-key="id"
              no-data-text="Malumot kiritilmagan"
              class="elevation-1 row-pointer"
            >
              <template v-slot:[`item.import_date`]="{ item }">
                {{ item.import_date | formatDate }}
              </template>

              <template v-slot:[`item.report_end_date`]="{ item }">
                {{ item.report_end_date | formatDate }}
              </template>

              <template v-slot:[`item.report_start_date`]="{ item }">
                {{ item.report_start_date | formatDate }}
              </template>

              <template v-slot:[`item.is_linked`]="{ item }">
                <div v-if="item.is_linked !== true">
                    <div class="red status__name">
                      bog'lanmagan
                    </div>
                </div>
                <div v-else>
                  <div class="success status__name">
                    bog'langan
                  </div>
                </div>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <action :index="item"></action>
              </template>
            </v-data-table>
          </div>
          <div>
            <p style="font-size: 15px"
            class="text-dark font-bold label label-lg label-light-success label-inline">Umumiy boshlang'ich narx: {{getTotalSum && numberWithSpaces(getTotalSum) || 0 }}</p>
            <br>
            <p style="font-size: 15px"
            class="text-dark font-bold label label-lg label-light-success label-inline">Umumiy qoldiq: {{getTotalRemainSum && numberWithSpaces(getTotalRemainSum) || 0 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './action'
import { numberWithSpaces } from "@/utils"
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      total_starting_amount:0,
      total_remain_amount:0,
    }
  },
  created() {
    this.$store.dispatch('getmainassets')
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'ID',
          value: 'id'
        },
        {
          text: this.$t('TABLE_HEADER.NAME'),
          value: 'name'
        },
        {
          text: 'Inventar #',
          value: 'inventar_number'
        },
        {
          text: 'Kirim sanasi',
          value: 'import_date'
        },
        {
          text: "Boshlang'ich narxi",
          value: 'starting_amount_new'
        },
        {
          text: 'Muddati,Oy',
          value: 'period_usage'
        },
        {
          text: 'Tugash sanasi',
          value: 'report_end_date'
        },
        {
          text: 'Kunlik amortizatsiya',
          value: 'daily_amortization_amount'
        },
        {
          text: 'Assosiy vosita qoldigi',
          value: 'remain_amount_overall'
        },
        {
          text: 'Hisobot davri amortizatsiya',
          value: 'amortization_for_report_period'
        },
        {
          text: 'Hisobot davri boshi',
          value: 'report_start_date'
        },
        {
          text: 'Hisobot Davri Boshidagi Qoldiq',
          value: 'report_start_date_remain'
        },
        {
          text: this.$t('TABLE_HEADER.REG_NUMBER'),
          value: 'reg_number'
        },
        {
          text: this.$t('TABLE_HEADER.COMMENT'),
          value: 'comment'
        },
        {
          text: this.$t('TABLE_HEADER.STORE'),
          value: 'placement_warehouse_name'
        },
        {
          text: this.$t('TABLE_HEADER.STATUS'),
          value: 'is_linked'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    getMainAssets() {
      return this.$store.state.requests.getmainassetsall
    },
    getTotalSum() {
      let data = this.$store.state.requests.getmainassetsall
      let sum = 0
      data.forEach(item => 
      {
        sum = sum + (+item.starting_amount)
      })
      return sum
    },
    getTotalRemainSum() {
      let data = this.$store.state.requests.getmainassetsall
      let sum = 0
      data.forEach(item => 
      {
        sum = sum + (+(item.report_start_date_remain).toString().replace(/,/g, ''))
      })
      return sum
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  methods: {
    numberWithSpaces,
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0

        this.$router.push('/details/' + item.id + '/')

        row.select(false)
      }
    },
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.MAIN_ASSETS.TITLE') },
      {
        title: this.$t('MENU.MAIN_ASSETS.MAIN_ASSETS_TITLE')
      }
    ])
  },
  components: {
    action
  }
}
</script>

<style scoped>
.status__name[data-v-d7d2f06a] {
    border-radius: 5px;
    text-align: center;
    color: #fff;
    padding: 1px 4px;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  min-height: 300px;
  width: 100%;
}
</style>
